.facilities-banner{
    min-height: 300px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('./Facilities/coverpic.jpg');
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}
.facilities-banner>h1{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 5rem;
    font-weight: 800;
    font-family: 'poppins';
    color: white;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.rooted-head{
    text-align: center;
    font-family: 'poppins';
    font-size: 2rem;
    color: #2a254d;
    text-transform: capitalize;
    margin-top:5%;
    margin-bottom: 5%;
    
}

.facilities-intro{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    margin: auto;
    gap: 20px;
    margin-top: 30px;
}

.facilities-intro-video{
    width: 100%;
    min-height: 300px;
    object-fit: fill;
}
.facilities-intro iframe{
    width: 100%;
    min-height: 415px;
    height: auto;
}

.facilities-intro-content>h1{
    font-size: 1.1rem;
    font-family: 'poppins';
    color: gray;
    font-weight: 400;
    line-height: 1.5;
}

.facilities-third-con{
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fac-thd-con-hd{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.fac-thd-con-hd>h1{
    font-size: 1.7rem;
    text-transform: capitalize;
    color: #2a254d;
    font-family: 'poppins';
    font-weight: 700
}

/* fac-thd-con-dis */
.fac-thd-con-dis{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: auto;
    width: 90%;
    margin: auto;
    gap: 20px;
}

.fac-thd-con-dis>div{
    width: 100%;
    height: 500px;
    overflow: hidden;
    /* border-top-right-radius: 50%;
    border-top-left-radius: 50%; */
    border-radius: 25px;
    position: relative;
    background-color: black;
}

.fac-thd-con-dis>div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
}

.fac-thd-con-dis-para{
    position: absolute;
    padding:20px;
    background-color: #f57005;
    min-width: 260px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    gap: 5px;
}

.fac-thd-con-dis-para>p{
    font-size: 1.3rem;
    font-family: 'poppins';
    font-weight: 600;
    color: white;
    text-transform: capitalize;
}

.fac-thd-con-dis-para>h5{
    font-size: 0.7rem;
    font-family: 'poppins';
    font-weight:600 ;
    text-decoration: underline;
    cursor: pointer;
}

/* facilities meru con */
.facilities-meru-con{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    row-gap: 30px;
    column-gap: 20px;
    padding-left: 6%;
    padding-right: 6%;
}
.facilities-meru-con-each{
    width: 100%;
    height: auto;
    overflow: hidden;
}

.facilities-meru-con-each-inner{
    background-image: linear-gradient(to left top, #d6eaec, #c2e3f0, #b5dbf7, #b5cffb, #c2c0f9);
    padding: 10px;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
    overflow: hidden;
    width: 100%;
    height: 400px;
}

.facilities-meru-con-each-inner>img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
}

.facilities-meru-con-each>h1{
    font-family: 'poppins';
    color: #353171;
    font-size: 1.5rem;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}
.facilities-meru-con-each>p{
    font-family: 'poppins';
    color: black;
    font-size: 0.9rem;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media screen and (max-width:1300px){
    .facilities-meru-con{
        grid-template-columns: repeat(2,1fr);
    }
}

@media all and (max-width:1200px){
    .fac-thd-con-dis{
        grid-template-columns: repeat(2,1fr);
    }
}

@media all and (max-width:1010.1234px){
    .facilities-intro{
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width:810px){
    .facilities-meru-con{
        grid-template-columns: repeat(1,1fr);
    }
}

@media all and (max-width:600px){
    .facilities-banner{
        min-height: 200px;
    }
    .facilities-banner>h1{
        font-size: 2rem;
    }
    .facilities-intro iframe{
        min-height: 295px;
    }
    .rooted-head{
        font-size: 1.3rem;
    }
    .facilities-intro-content>h1{
        font-size: 0.9rem;
    }
    .fac-thd-con-dis{
        grid-template-columns: 1fr;
    }
    .fac-thd-con-dis>div{
        height: 399px;
    }
}
@media screen and (max-width:410px){
    .facilities-meru-con{
        grid-template-columns: repeat(1,1fr);
    }
    .facilities-meru-con-each{
        width: 100%;
        height: auto;
    }
}