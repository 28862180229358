.career-banner{
    height: 300px;
    overflow: hidden;
    background-color: black;
    position: relative;
}

.career-banner>img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    opacity: 0.7;
}

.career-banner>h1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 3rem;
    font-family: 'poppins';
    font-weight: 600;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.career-ban-head-para{
    margin-left: 5%;
    font-size: 1.2rem;
    font-family: 'poppins';
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 40px;
    margin-bottom: 40px;
    color: #FF5C35;
}

.career-boxes{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: auto;
    width: 90%;
    margin: auto;
    gap: 10px;
    margin-bottom: 5%;
}

.career-boxes>div{
    background-color:white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 3px 9px 21px -16px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all 0.3s ease;
}

.career-boxes>div h1{
    font-size: 1.4rem;
    font-family: 'poppins';
    font-weight: 700;
    text-transform: capitalize;
    color: black;
}

.career-boxes>div h2{
    font-size: 1.2rem;
    font-family: 'poppins';
    font-weight: 500;
    text-transform: capitalize;
    color: black;
}

.career-boxes>div p{
    font-family: 'poppins';
    font-size: 0.9rem;
    line-height: 1.5;
    margin-top: 15px;
    margin-bottom: 15px;
}

.career-boxes>div h3{
    font-family: 'poppins';
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: capitalize;
    color: black;
}

.career-boxes>div:hover{
    background-color: #f57a25;
    cursor: pointer;
}

.career-boxes>div:hover p,.career-boxes>div:hover h1, .career-boxes>div:hover h2, .career-boxes>div:hover h3{
    
    color: white;
}

@media all and (max-width:1400px){
    .career-boxes{
        grid-template-columns: repeat(3,1fr);
    }

    .career-boxes>div{
        padding: 20px;
    }
}

@media screen and (max-width:1000px){
    .career-boxes{
        grid-template-columns: repeat(2,1fr);
    }

    .career-boxes>div h1{
        font-size: 1rem;
        font-weight: 400;
    }

    .career-boxes>div h2{
        font-size: 1rem;
        font-weight: 500;
    }

    .career-boxes>div h3{
        font-size: 1rem;
    }
}

@media screen and (max-width:650px){
    .career-boxes{
        grid-template-columns: repeat(1,1fr);
    }
    .career-boxes>div{
        gap: 5px;
    }
    .career-boxes>div p{
        margin-top: 8px;
        margin-bottom: 8px;
    }
}