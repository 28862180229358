.certificates-con{
    min-height: 90vh;
    padding-top: 120px;
    padding-left: 20px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.certificates-con>h2{
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: underline;
}

.certificates-con>h1{
    font-size: 1.2rem;
    font-weight: 500;
}

.certificates-con>a{
    text-decoration: underline;
    text-transform: capitalize;
    color: blue;
}

.certificates-con>a:hover{
    color: red;
    cursor: pointer;
}

.certificates-con table{
    border-collapse: collapse;
    max-width:1500px;
    width: 100%;
}

.certificates-con table thead tr th{
    background-color: rgb(2, 131, 190);
    border: 1px solid black;
    padding: 5px 10px;
    font-size: 1.1rem;
    font-weight: 500;
    color: white;
}

.certificates-con table td{
    /* border-collapse: collapse; */
    border: 1px solid black;
    padding: 5px 10px;
}

.certificates-con table tbody tr td:nth-child(1){
    text-align: center;
}

.certificate-details td:nth-child(3){
    text-align: center;
}

