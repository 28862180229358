
.faq-con{
    width: 70%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.faq-con-header{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.faq-con-header>h1{
    font-size: 1.5rem;
    font-family: 'poppins';
    font-weight: 700;
    text-transform: capitalize;
    color: #f57a25;
}
.faq-con-header>p{
    font-size: 1.1rem;
    font-family: 'poppins';
}

/* faq-con-content */
.faq-con-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.faq-con-content>div{
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.faq-con-content>div h1{
    font-size: 1.3rem;
    font-family: 'poppins';
    font-weight: 600;
    color: #2f4b67;
}
.faq-con-content>div p{
    font-size: 1rem;
    font-family: 'poppins';

}

@media all and (max-width:900px){
    .faq-con{
        width: 90%;
    }
}

@media all and (max-width:550px){
    .faq-con{
        width: 90%;
    }
    .faq-con-header>h1{
        font-size: 1.2rem;
    }
    .faq-con-header>p{
        font-size: 0.9rem;
    }
    .faq-con-content>div h1{
        font-size: 1rem;
    }
    .faq-con-content>div p{
        font-size: 0.8rem;
    }
}